
import WOW from 'wowjs';

export class HomePage {

    constructor() {
        $(() => {

            console.log("Loaded Page");

            new WOW.WOW({
                live: false
            }).init();
        });
    }
}
